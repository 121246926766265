import { Injectable } from '@angular/core';
import { Client, Environment } from 'src/configurations/configuration-map';
import { environment } from 'src/environments/environment';
import { CookieService } from '../../Cookie/cookie.service';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  public constructor(
    private _cookieService: CookieService,
    private _localizeRouterService : LocalizeRouterService,
  ) {

  }

  public getClient(): Client
  {
      return environment.environment == 'development' ? environment.client as Client : window.location.hostname.split('.')[0] as Client 
  }

  public getEnvironment(): Environment
  {
    return environment.environment as Environment
  }

  /* get current language */
  public getCurrentLanguage(): string  
  {
    return this._localizeRouterService.parser.currentLang ?? 'en';
    
    //if(!this._localizeRouterService.parser.locales) return 'en'
    //let language = this._localizeRouterService.parser.getLocationLang()
  
    /* 1) cookie */
    //language = language ?? this._cookieService.getCookie('language') 

    /*if(!language.length) {
      // 2) browser & 3) default
      language = this.getBrowserLanguage() ?? 'en'
    }*/
  }

  public translateRoute(path: string) 
  {
    const p = path.startsWith('/') ? path : '/' + path

    return this._localizeRouterService.translateRoute(p)
  }

  /* get browser language */
  public getBrowserLanguage(): string | null {
    if(!navigator?.language) return null

    return (navigator.language || '').split('-')[0];
  }
}
