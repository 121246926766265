import { Component, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { FixtureType } from 'src/app/enums/fixture/fixtureType';
import { UserType } from 'src/app/enums/user/userType';
import { Auth } from 'src/app/interfaces/State/Auth/auth';
import { Index } from 'src/app/interfaces/State/MyTickets/index';
import { Ticket } from 'src/app/interfaces/State/MyTickets/ticket';
import { AlertService } from 'src/app/services/Alert/alert.service';
import { HelperService } from 'src/app/services/Misc/Helper/helper.service';
import { ModalService } from 'src/app/services/Modal/modal.service';
import { MyTicketService } from 'src/app/services/MyTicket/my-ticket.service';
import { TransferService } from 'src/app/services/Transfer/transfer.service';

@Component({
  selector: 'app-my-ticket',
  templateUrl: './my-ticket.component.html',
  styleUrls: ['./my-ticket.component.scss']
})
export class MyTicketComponent implements OnInit {
  @Input() fixtureWrapperObject: Index = {} as Index
  @Input() auth: Auth = {} as Auth
  @Input() printTrigger: Subject<number> = {} as Subject<number>;
  @Input() isInvoiceIssued?: Boolean = false

  public downloading: Array<number> = []
  public printing: Array<number> = []
  public pdfUrl?: string
  public ticketUrl?: string
  public showBackToMatch: Boolean = false
  public mapOnly: boolean = false
  error: string = ''
  success: boolean = false
  isRequesting: Array<string> = []

  constructor(
    private _myTicketService: MyTicketService,
    private sanitizer: DomSanitizer,
    private _route: ActivatedRoute,
    private _alertService: AlertService,
    private _transferService: TransferService,
    private _translateService: TranslateService,
    private _localizeRouterService: LocalizeRouterService,
    private _helperService: HelperService,
  ) {
    this.showBackToMatch = this._route.snapshot.queryParamMap.get('only-map') ? false : true
    this.mapOnly = this._route.snapshot.queryParamMap.get('only-map') ? true : false
  }

  ngOnInit(): void {
    if (this.isCashier()) {
      this.printTrigger.subscribe(async (v) => {
        if (v !== 0) {
          for (const t of this.fixtureWrapperObject.tickets) {
            await this.printTicket(t.seat_id);
            await new Promise(r => setTimeout(r, 250));
          }
        }
      })
    }
  }

  public async printTicket(seatId: number) {
    if (this.auth.user?.type !== UserType.CASHIER) return;

    if (this.printing.includes(seatId)) return;

    if (!this.auth.user.printer?.address?.length) return;

    this.printing.push(seatId);

    const ticketData = this.fixtureWrapperObject.tickets.find(v => v.seat_id === seatId);

    if (!ticketData) return;

    await this._myTicketService.printTicket(ticketData, this.fixtureWrapperObject.fixture.teams, this.auth.user.printer.address);

    const index = this.printing.indexOf(seatId, 0);
    if (index > -1) {
      this.printing.splice(index, 1);
    }
  }

  public async downloadTicket(seatId: number, isMobile: boolean = false) {
    if (this.downloading.includes(seatId)) return;

    this.downloading.push(seatId)

    try {
      const res = await this._myTicketService.downloadTicket(this.fixtureWrapperObject.fixture.slug, seatId)

      const index = this.downloading.indexOf(seatId, 0);
      if (index > -1) {
        this.downloading.splice(index, 1);
      }

      if (res.success) {
        if (isMobile) {
          this.pdfUrl = res.data.url
        } else {
          window.open(res.data.url, '_blank');
        }
      }
    } catch (e: any) {
      const errorHappenedText = this._translateService.instant('general.error-happened');

      this._alertService.error(
        errorHappenedText,
        e.error.error ?? '',
      )

      const index = this.downloading.indexOf(seatId, 0);
      if (index > -1) {
        this.downloading.splice(index, 1);
      }
    }

  }

  public isDownloading(seatId: number) {
    return this.downloading.includes(seatId)
  }

  public isPrinting(seatId: number) {
    return this.printing.includes(seatId)
  }

  public printIdentifier(ticket: Ticket) {
    return this.sanitizer.bypassSecurityTrustHtml(ticket.identifier_src)
  }

  public async getAppleWalletPass(seatId: number) {
    const res = await this._myTicketService.getAppleWalletPass(this.fixtureWrapperObject.fixture.slug, seatId)
  }

  public isCashier(): boolean {
    return this.auth.user.type == UserType.CASHIER
  }

  public isCustomer(): boolean {
    return this.auth.user.type == UserType.CUSTOMER
  }

  public fixtureTypes() {
    return FixtureType
  }

  public isFixtureTypes(fixture: any, eventType: string,) {
    return eventType == fixture.event_type
  }

  public invoiceUrl(ticket: Ticket) {
    let queryString = ''

    if (this.mapOnly) {
      queryString = '?only-map=1'
    }

    return this._helperService.translateRoute(`order/${ticket.order_id}/invoice${queryString}`);
  }

  public mobileTicketUrl(ticket: Ticket) {
    return this._helperService.translateRoute(`my-tickets/fixtures/${this.fixtureWrapperObject.fixture.slug}/ticket/${ticket.uuid}`);
  }

  get queryString() {
    return this._route.snapshot.queryParams
  }

  async rekove(ticket: Ticket) {
    if (this.isRequesting.includes(ticket.uuid!)) return;

    this.isRequesting.push(ticket.uuid!)

    this.error = ""

    const res = await this._transferService.revokeTicket(this.fixtureWrapperObject.fixture.slug, ticket.uuid!)

    const index = this.isRequesting.indexOf(ticket.uuid!);
    if (index > -1) { // Only splice if the element exists
      this.isRequesting.splice(index, 1); // Removes one element at the specified index
    }

    if (res === true) {
      this.success = true
      const revokeSuccessfulText = this._translateService.instant('my-ticket.revoke-successful');

      this._alertService.success(revokeSuccessfulText, undefined, false)

      return;
    }

    this.success = false
    this.error = res.error
    this._alertService.error(undefined, res.error)
  }

}
